import "@fortawesome/fontawesome-free/css/all.css"
import Vue from "vue"
import Vuetify from "vuetify/lib"
import VueCryptojs from "vue-cryptojs"
import { customThemes } from "./themes.js"

// Import aller Icons
import LeafIcon from "@/components/icons/serp_items/LeafIcon.vue"
import MoneyIcon from "@/components/icons/serp_items/MoneyIcon.vue"
import ShoppingIcon from "@/components/icons/serp_items/ShoppingIcon.vue"
import VideoIcon from "@/components/icons/serp_items/VideoIcon.vue"
import ImagesIcon from "@/components/icons/serp_items/ImagesIcon.vue"
import AnswerBoxIcon from "@/components/icons/serp_items/AnswerBoxIcon.vue"
import MagnifyIcon from "@/components/icons/MagnifyIcon.vue"
import DBIcon from "@/components/icons/DBIcon.vue"
import LogoutIcon from "@/components/icons/LogoutIcon.vue"
import BellIcon from "@/components/icons/BellIcon.vue"
import FindResultsOn from "@/components/icons/serp_items/FindResultsOn.vue"
import CarouselIcon from "@/components/icons/serp_items/CarouselIcon.vue"
import LocalPack from "@/components/icons/serp_items/LocalPack.vue"
import PeopleAlsoAsked from "@/components/icons/serp_items/PeopleAlsoAsked.vue"
import RelatedSearches from "@/components/icons/serp_items/RelatedSearches.vue"
import RichSnippet from "@/components/icons/serp_items/RichSnippet.vue"
import MultiCarouselIcon from "@/components/icons/serp_items/MultiCarouselIcon.vue"
import PopularProducts from "@/components/icons/serp_items/PopularProducts.vue"
import GoogleReviews from "@/components/icons/serp_items/GoogleReviews.vue"
import GooglePosts from "@/components/icons/serp_items/GooglePosts.vue"
import GoogleFlights from "@/components/icons/serp_items/GoogleFlights.vue"
import FeaturedSnippet from "@/components/icons/serp_items/FeaturedSnippet.vue"
import LocalServices from "@/components/icons/serp_items/LocalServices.vue"
import Recipes from "@/components/icons/serp_items/Recipes.vue"
import CommercialUnits from "@/components/icons/serp_items/CommercialUnits.vue"
import StocksBox from "@/components/icons/serp_items/StocksBox.vue"
import QuestionsAndAnswers from "@/components/icons/serp_items/QuestionsAndAnswers.vue"
import Podcasts from "@/components/icons/serp_items/Podcasts.vue"
import ScholarlyArticles from "@/components/icons/serp_items/ScholarlyArticles.vue"
import TopSights from "@/components/icons/serp_items/TopSights.vue"
import VisualStories from "@/components/icons/serp_items/VisualStories.vue"
import MapIcon from "@/components/icons/serp_items/MapIcon.vue"
import HotelsPack from "@/components/icons/serp_items/HotelsPack.vue"
import MentionCarousel from "@/components/icons/serp_items/MentionCarousel.vue"
import Events from "@/components/icons/serp_items/Events.vue"
import TopStories from "@/components/icons/serp_items/TopStories.vue"
import PeopleAlsoSearch from "@/components/icons/serp_items/PeopleAlsoSearch.vue"
import KnowledgeGraph from "@/components/icons/serp_items/KnowledgeGraph.vue"
import Jobs from "@/components/icons/serp_items/Jobs.vue"
import Twitter from "@/components/icons/serp_items/Twitter.vue"

Vue.use(VueCryptojs)
Vue.use(Vuetify)

// Funktion zum Entschlüsseln der `company_id`
function getCompanyId() {
    if (localStorage.company_id) {
        return Vue.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(Vue.CryptoJS.enc.Utf8)
    }
    return null
}

// Funktion, um das passende Theme anhand der `company_id` zu holen
function getTheme(company_id) {
    const theme = customThemes.find((e) => e.id == company_id)
    return {
        light: theme ? theme.light : customThemes[0].light,
        dark: theme ? theme.dark : customThemes[0].dark
    }
}

const company_id = getCompanyId()
const { light, dark } = getTheme(company_id)

const vuetify = new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light,
            dark
        }
    },
    icons: {
        iconfont: "fa",
        values: {
            people_also_search: { component: PeopleAlsoSearch },
            knowledge_graph: { component: KnowledgeGraph },
            jobs: { component: Jobs },
            twitter: { component: Twitter },
            commercial_units: { component: CommercialUnits },
            stocks_box: { component: StocksBox },
            questions_and_answers: { component: QuestionsAndAnswers },
            podcasts: { component: Podcasts },
            scholarly_articles: { component: ScholarlyArticles },
            top_sights: { component: TopSights },
            visual_stories: { component: VisualStories },
            map: { component: MapIcon },
            hotels_pack: { component: HotelsPack },
            mention_carousel: { component: MentionCarousel },
            events: { component: Events },
            top_stories: { component: TopStories },
            recipes: { component: Recipes },
            local_services: { component: LocalServices },
            featured_snippet: { component: FeaturedSnippet },
            google_flights: { component: GoogleFlights },
            google_posts: { component: GooglePosts },
            google_reviews: { component: GoogleReviews },
            popular_products: { component: PopularProducts },
            multi_carousel: { component: MultiCarouselIcon },
            find_results_on: { component: FindResultsOn },
            carousel: { component: CarouselIcon },
            local_pack: { component: LocalPack },
            people_also_asked: { component: PeopleAlsoAsked },
            related_searches: { component: RelatedSearches },
            rich_snippet: { component: RichSnippet },
            organic: { component: LeafIcon },
            paid: { component: MoneyIcon },
            images: { component: ImagesIcon },
            shopping: { component: ShoppingIcon },
            video: { component: VideoIcon },
            answer_box: { component: AnswerBoxIcon },
            magnify: { component: MagnifyIcon },
            database: { component: DBIcon },
            logout: { component: LogoutIcon },
            bell: { component: BellIcon }
        }
    }
})

// Funktion, um das Theme zur Laufzeit zu ändern
Vue.prototype.$setTheme = () => {
    console.log("settign theme")

    const company_id = getCompanyId()
    console.log("company_id", company_id)

    const { light, dark } = getTheme(company_id)

    vuetify.framework.theme.themes.light = light
    vuetify.framework.theme.themes.dark = dark
    console.log("Theme wurde aktualisiert:", light, dark)
}

export default vuetify
