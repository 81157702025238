<template>
    <v-app id="inspire">
        <v-snackbar top timeout="30000" v-model="showUpdateMessage" color="#fcba03">
            <v-icon>mdi-update</v-icon
            ><span class="font-weight-bold pl-2">
                A new update has been released. Reloading application in
                {{ timer }} seconds!
            </span>
        </v-snackbar>
        <v-overlay :value="offline">
            <div class="pb-0">
                <div class="download-label">
                    <span class="mx-auto">Connection lost. Reconnecting...</span>
                </div>
                <v-progress-linear style="width: 310px" label="downloading" color="white" indeterminate rounded height="6"></v-progress-linear>
            </div>
        </v-overlay>

        <!-- --------------------------- -->
        <!-- -------- left nav --------- -->
        <!-- --------------------------- -->

        <v-navigation-drawer v-if="usermail" v-model="drawer" dark app clipped :expand-on-hover="expand" id="nav">
            <!-- <hr class="navdivider" style="margin-top: 15px" /> -->
            <div>
                <!-- <v-list-item to="/content-projects" color="white" class="navitem pl-10">
                    <v-list-item-icon>
                        <v-icon>mdi-text-box-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Content Projects</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <v-list-group v-if="$store.state.research_suite" v-model="researchnav" prepend-icon="mdi-magnify" append-icon="mdi-chevron-down" color="white" class="pl-0" id="cs_nav">
                    <template v-slot:activator>
                        <v-list-item-title style="white-space: nowrap" class="white--text"> Research Suite </v-list-item-title>
                    </template>
                    <v-divider></v-divider>

                    <v-list shaped class="pl-0 pr-1" dense>
                        <v-list-item color="white" v-for="item in items" :key="item.text" link :to="item.redirect" @click="item.model = false" class="navitem pl-10">
                            <v-list-item-icon>
                                <v-icon :class="!expand ? 'pl-3' : 'pl-0'" size="20px" class="">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-list-group>
            </div>
            <!-- <hr class="navdivider" /> -->
            <v-divider></v-divider>
            <!-- <v-divider class="pl-5"></v-divider> -->

            <v-list-group
                v-if="$store.state.content_suite && ($store.state.company_status_content_suite == 'active' || $store.state.company_status_content_suite == 'testing')"
                prepend-icon="mdi-text-box-edit"
                append-icon="mdi-chevron-down"
                color="white"
                shaped
                class="pl-0"
                id="cs_nav">
                <template v-slot:activator>
                    <v-list-item-title class="white--text"> Content Suite </v-list-item-title>
                </template>
                <v-divider></v-divider>
                <!-- <v-list-item to="/content-project/setup" color="white" class="navitem pl-10">
                        <v-list-item-icon>
                            <v-icon>mdi-plus-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>New Project</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                <v-list shaped class="pl-0 pr-1" dense>
                    <v-list-item to="/content-projects" color="white" class="navitem pl-10">
                        <v-list-item-icon>
                            <v-icon :class="!expand ? 'pl-3' : 'pl-0'" size="20px">mdi-text-box-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title> Projects</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$store.state.content_suite_group.id == 1 || $store.state.admin" to="/wdf-idf/" color="white" class="navitem pl-10">
                        <v-list-item-icon>
                            <v-icon :class="!expand ? 'pl-3' : 'pl-0'" size="20px">mdi-text-recognition</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>WDF*IDF</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-list-group>
            <v-divider></v-divider>
            <v-list-group
                v-if="$store.state.monitoring_suite && ($store.state.company_status_monitoring_suite == 'active' || $store.state.company_status_monitoring_suite == 'testing') && lutz"
                prepend-icon="mdi-chart-timeline-variant"
                append-icon="mdi-chevron-down"
                color="white"
                shaped
                class="pl-0"
                id="cs_nav">
                <template v-slot:activator>
                    <v-list-item-title class="white--text"> QuickSEO</v-list-item-title>
                </template>
                <v-divider></v-divider>
                <!-- <v-list-item to="/content-project/setup" color="white" class="navitem pl-10">
                        <v-list-item-icon>
                            <v-icon>mdi-plus-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>New Project</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                <v-list shaped class="pl-0 pr-1" dense>
                    <v-list-item shaped to="/quickseo/monitoring" color="white" class="navitem pl-10">
                        <v-list-item-icon>
                            <v-icon>mdi-view-dashboard</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Monitoring</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item rounded to="/quickseo/internal-searches" color="white" class="navitem pl-10">
                        <v-list-item-icon>
                            <v-icon>mdi-store-search</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Internal Searches</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-list-group>
            <div v-else-if="$store.state.monitoring_suite && ($store.state.company_status_monitoring_suite == 'active' || $store.state.company_status_monitoring_suite == 'testing')">
                <v-divider></v-divider>

                <v-list-item shaped to="/monitoring" color="white" class="navitem pl-10">
                    <v-list-item-icon>
                        <v-icon>mdi-chart-timeline-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Monitoring Suite</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item
                    shaped
                    :to="$store.state.company_status_monitoring_suite == 'test_ended' ? '/pricing' : '/monitoring'"
                    :color="$store.state.company_status_monitoring_suite == 'test_ended' ? 'grey' : 'white'"
                    class="navitem pl-10">
                    <v-list-item-icon>
                        <v-badge color="grey" icon="mdi-lock">
                            <v-icon :color="$store.state.company_status_monitoring_suite == 'test_ended' ? 'grey' : 'white'">mdi-chart-timeline-variant</v-icon>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="monitoring_title">Monitoring Suite</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
            </div>
            <!-- 
            <div v-if="$store.state.monitoring_suite">
                <v-divider></v-divider>

                <v-list-item rounded to="/internal-searches" color="white" class="navitem pl-10">
                    <v-list-item-icon>
                        <v-icon>mdi-store-search</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Internal Searches</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </div> -->
            <template v-slot:append>
                <div style="width: 100%">
                    <div v-if="$helpers.isActionAllowed(42, user_rights)" :class="expand ? 'switch-holder' : 'switch-holder switch-holder-expand'">
                        <v-switch @change="switchAdvancedMode()" class="my-auto mode_switch" v-model="advanced_switch" light inset color="primary" hide-details dense></v-switch>
                        <div :class="expand ? 'switch-label' : 'switch-label expand'">
                            <span style="white-space: nowrap">Advanced Mode</span>
                            <!-- <v-chip class="ml-2 betachip" x-small color="red">BETA</v-chip> -->
                        </div>
                    </div>
                    <v-list shaped nav class="pl-0 pr-1">
                        <!-- <v-list-item v-if="$store.state.show_settings_page" class="mt-auto" link to="/competitors">
                        <v-list-item-action>
                            <v-icon class="pl-2" color="grey lighten-2">mdi-chart-box-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="white--text text--darken-1 subnavtext">Competitors</v-list-item-title>
                    </v-list-item> -->

                        <v-list-item class="" link to="/knowledge">
                            <v-list-item-action>
                                <v-icon class="pl-2" color="grey lighten-2">mdi-video</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="white--text text--darken-1 subnavtext">Tutorial</v-list-item-title>
                        </v-list-item>

                        <v-list-item v-if="$store.state.show_settings_page && $store.state.admin" link to="/settings">
                            <v-list-item-action>
                                <v-icon class="pl-2" color="grey lighten-2">mdi-cog</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="white--text text--darken-1 subnavtext">Settings</v-list-item-title>
                        </v-list-item>

                        <v-list-item link to="/logout">
                            <v-list-item-action>
                                <v-icon class="pl-2" color="grey lighten-2">mdi-logout-variant</v-icon>
                            </v-list-item-action>
                            <v-list-item-title class="white--text text--darken-1 subnavtext">Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </template>
        </v-navigation-drawer>
        <!-- <div style="position: relative; width: 100%; height: 100%"> -->

        <v-dialog id="endoftest-dialog" v-if="!$vuetify.breakpoint.smAndUp" v-model="mobileoverlay" width="100%" fullscreen style="margin: 0px !important" persistent>
            <div class="maincard">
                <div class="vh">
                    <div>
                        <div class="wrap">
                            <img src="./assets/maintenance.svg" width="200px" class="illustration" alt="" />
                            <h1>Maintenance mode</h1>
                            <h2>
                                <p>Sorry for the inconvenience.<br />Quaros mobile version is currently undergoing maintenance.<br /><br /></p>
                            </h2>
                            <div class="msgbox">
                                <span class="text">Thank you for your understanding.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>

        <!-- </div> -->
        <!-- -------------------------- -->
        <!-- -------- top nav --------- -->
        <!-- -------------------------- -->
        <v-app-bar app clipped-left v-if="usermail" id="nav2" height="64px">
            <div class="burger-wrapper">
                <input type="checkbox" id="hamburg" @click.stop="shownav" />
                <label for="hamburg" class="hamburg">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </label>
            </div>

            <v-toolbar-title id="tooltitle" class="align-center" @click="$route.path !== '/' ? $router.push('/') : idle">
                <div id="logo-div" v-if="$route.path.substring(0, 9) === '/quickseo'">
                    <img id="logo" :src="logoSourceLutz" />
                </div>
                <div id="logo-div" v-if="company_id == 1547">
                    <img id="logo" :src="apollonsrc" />
                </div>
                <div id="logo-div" v-else>
                    <img id="logo" :src="logoSource" />
                </div>
            </v-toolbar-title>

            <!-- <v-btn
                id="updatebtn"
                dark
                small
                @click="
                    user_settings.show_update_message = true
                    $store.dispatch('CHANGE_USER_SETTINGS', user_settings)
                ">
                <v-icon small class="ml-0 mr-1">mdi-bell</v-icon> January Update</v-btn
            > -->

            <v-spacer id="top_nav"></v-spacer>
            <CompanySwitch class="ma-auto"></CompanySwitch>
            <!-- checkout pricing page -->
            <!-- <checkout v-if="usermail && company_status == 'testing'"></checkout> -->
            <v-btn icon v-if="usermail" id="" @click="$router.push('/pricing')">
                <v-icon class="">mdi-chess-queen</v-icon>
            </v-btn>
            <checkout-success v-if="showCheckoutSuccess"></checkout-success>
            <!-- OMR Review -->
            <!-- <OmrReview v-if="usermail && company_status != 'testing'" class="ml-5"></OmrReview> -->
            <!-- update card -->
            <!-- update card -->
            <update-card
                @close="
                    user_settings.show_update_message = false
                    $store.dispatch('CHANGE_USER_SETTINGS', user_settings)
                "
                :show="user_settings.show_update_message"
                v-if="usermail"></update-card>
            <!-- notifications -->
            <notifications
                v-if="usermail"
                @showUpdateInfo="
                    user_settings.show_update_message = true
                    $store.dispatch('CHANGE_USER_SETTINGS', user_settings)
                "></notifications>

            <div class="vl"></div>
            <v-menu open-on-hover :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <div id="usersection" v-bind="attrs" v-on="on">
                        <v-row no-gutters class="my-auto">
                            <v-col class="pr-2">
                                <v-avatar color="primary" size="38" class="pr-0 avat my-auto">
                                    <span class="initials">{{ initials }}</span>
                                </v-avatar>
                            </v-col>
                            <v-col id="userdata_nav" class="pr-0">
                                <div class="namepart">
                                    {{ decryptedName }}
                                </div>
                                <div class="mailpart pb-0">
                                    {{ decryptedMail }}
                                </div>
                            </v-col>

                            <v-icon id="usersection_chevron" small class="pb-1 pl-2 pr-5">mdi-chevron-down</v-icon>
                        </v-row>
                    </div>
                </template>
                <v-list class="py-0">
                    <div class="usermenuheader">
                        <v-row class="py-0 pt-1 pl-2">
                            <v-col cols="3" class="py-0 pr-4">
                                <v-avatar size="38" class="pr-0 avat2">
                                    <span>{{ initials }}</span>
                                </v-avatar>
                            </v-col>
                            <v-col class="idpart pl-1 pr-0">
                                <div class="namepart">
                                    {{ decryptedName }}
                                </div>
                                <div class="mailpart">
                                    {{ decryptedMail }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="px-3 mb-3">
                        <v-switch hide-details prepend-icon="mdi-theme-light-dark" v-model="user_settings.dark_mode" @change="changeTheme" color="darkgreen" label="Dark Mode"></v-switch>
                        <v-switch
                            v-if="$helpers.isActionAllowed(42, user_rights)"
                            hide-details
                            prepend-icon="mdi-book-open"
                            v-model="advanced_switch"
                            color="darkgreen"
                            @change="switchAdvancedMode()"
                            label="Advanced Mode"></v-switch>
                    </div>

                    <v-divider></v-divider>
                    <div class="pa-2 usermenuactions">
                        <v-btn class="usermenulogoutbtn" @click="$router.push('/logout')" small rounded dark>logout</v-btn>
                    </div>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-main id="content">
            <KeepAlive max="4" include="Ideas,Suggest,KeywordPlanner,AdhocWdfIdf" exclude="cs-editor,cs-projects">
                <router-view :key="$route.fullPath" v-slot="{ Component }">
                    <transition name="fade">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </KeepAlive>

            <!-- <router-view v-slot="{ Component, route }">
                <transition name="fade" mode="out-in">
                    <keep-alive>
                        <component v-if="route.matched.length > 0" :is="Component"></component>
                        <div v-else>Not Found</div>
                    </keep-alive>
                </transition>
            </router-view> -->
        </v-main>
        <dashboard-core-settings
            v-if="usermail && $route.path !== '/content-projects' && !$route.path.includes('/content-editor') && !$route.path.includes('/wdf-idf') && !$route.path.includes('/competitors')"
            v-model="expandOnHover" />
    </v-app>
</template>
<script>
    import Vue from "vue"

    export default {
        props: {
            source: String
        },
        components: {
            UpdateCard: () => import("./components/UpdateCard"),
            DashboardCoreSettings: () => import("./components/Settings"),
            Checkout: () => import("./components/checkout/Checkout"),
            Notifications: () => import("./components/Notifications"),
            CheckoutSuccess: () => import("./components/checkout/CheckoutSuccess"),
            CheckoutCanceled: () => import("./components/checkout/CheckoutCanceled"),
            CompanySwitch: () => import("./components/CompanySwitch")
            // OmrReview: () => import("./components/OmrReview")
        },

        data: () => ({
            mobileoverlay: true,
            // mode: false,
            advanced_switch: false,
            researchnav: false,
            sidenav_variant: true,
            showCheckoutSuccess: false,
            showUpdateInfo: false,
            swatches: [["#1cedb2", "#11bd8c", "#009f89"]],
            selectedColor: null,
            // themeswitch: false,
            companyGroupJoined: false,
            inactiveCheckDone: false,
            planChecked: false,
            logoSource: require("./assets/quaro_final_rgb.svg"),
            logoSourceLutz: require("./assets/quickseofinal2.jpg"),
            apollonsrc: require("./assets/apollon_logo.png"),
            tabItems: [
                {
                    name: "Advanced",
                    price: "229,99",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi. Donec quis sapien ac diam interdum ullamcorper. Aenean blandit elit mi, a euismod tortor convallis vel. Aliquam nec urna dignissim, dignissim eros a, volutpat metus. Vestibulum vel placerat mi. Sed tincidunt hendrerit tellus eget condimentum. Phasellus pellentesque augue magna, nec elementum nunc rutrum sit amet. Donec lobortis ullamcorper laoreet. "
                },
                {
                    name: "Pro",
                    price: "479,99",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi. Donec quis sapien ac diam interdum ullamcorper. Aenean blandit elit mi, a euismod tortor convallis vel. Aliquam nec urna dignissim, dignissim eros a, volutpat metus. Vestibulum vel placerat mi. Sed tincidunt hendrerit tellus eget condimentum. Phasellus pellentesque augue magna, nec elementum nunc rutrum sit amet. Donec lobortis ullamcorper laoreet. "
                },
                {
                    name: "Enterprise",
                    price: "889,99",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi. Donec quis sapien ac diam interdum ullamcorper. Aenean blandit elit mi, a euismod tortor convallis vel. Aliquam nec urna dignissim, dignissim eros a, volutpat metus. Vestibulum vel placerat mi. Sed tincidunt hendrerit tellus eget condimentum. Phasellus pellentesque augue magna, nec elementum nunc rutrum sit amet. Donec lobortis ullamcorper laoreet. "
                }
            ],
            tab: null,
            query: false,
            expand: true,
            timer: 30,
            showUpdateMessage: false,
            expandOnHover: false,
            drawer: true,
            dropItems: [],
            get username() {
                if (localStorage.getItem("username")) {
                    if (localStorage.getItem("username") != "[object Object],[object Object]") {
                        return localStorage.getItem("username")
                    } else {
                        return
                    }
                } else {
                    return
                }
            },
            get usermail() {
                if (localStorage.getItem("user")) {
                    if (localStorage.getItem("user") != "[object Object],[object Object]") {
                        return localStorage.getItem("user")
                    } else {
                        return
                    }
                } else {
                    return
                }
            },
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            offline: false
        }),
        computed: {
            company_id() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            },
            lutz() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) == 32
            },
            decryptedMail() {
                if (this.usermail.length > 0) {
                    return this.CryptoJS.AES.decrypt(this.usermail, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            decryptedName() {
                if (this.username.length > 0) {
                    return this.CryptoJS.AES.decrypt(this.username, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            initials() {
                let splittedName = this.decryptedName.split(" ")
                let initials = ""
                splittedName.forEach((namepart) => {
                    let firstletter = namepart.substring(0, 1)
                    initials += firstletter
                })
                return initials
            },
            user_rights() {
                return this.$store.state.user_rights
            },
            company_status() {
                return this.$store.state.company_status
            },
            company_status_content_suite() {
                return this.$store.state.company_status_content_suite
            },
            items() {
                return this.$store.state.nav_items
            },
            user_settings: {
                get: function () {
                    return this.$store.state.user_settings
                }
                // set: function (newValue) {
                //     this.$store.dispatch("CHANGE_USER_SETTINGS", newValue)
                // }
            }
        },

        methods: {
            switchAdvancedMode() {
                this.user_settings.advancedMode = !this.user_settings.advancedMode
                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            changeThemeColor() {
                this.$vuetify.theme.themes.light.primary = this.selectedColor
                this.$vuetify.theme.themes.dark.primary = this.selectedColor
            },
            changeTheme() {
                this.$vuetify.theme.light = !this.$vuetify.theme.light
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            changeSettings() {
                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            onCancel() {
                console.log("paypal payment canceled")
            },
            onError() {
                console.log("error on paypal-payment")
            },
            onApprove: function (data, actions) {
                alert("You have successfully created subscription " + data.subscriptionID)
            },
            createSubscriptionEnterprise: function (data, actions) {
                return actions.subscription.create({
                    /* Creates the subscription */
                    plan_id: "P-5AM96077DD428871BMBNF2BI"
                })
            },
            createSubscriptionPro: function (data, actions) {
                return actions.subscription.create({
                    /* Creates the subscription */
                    plan_id: "P-5AM96077DD428871BMBNF2BI"
                })
            },
            createSubscriptionAdvanced: function (data, actions) {
                return actions.subscription.create({
                    /* Creates the subscription */
                    plan_id: "P-5AM96077DD428871BMBNF2BI"
                })
            },
            clickNotification(notification) {
                if (notification.type == "2") {
                    this.$router.push("/brand-index/" + notification.listname)
                }
            },
            shownav() {
                if (this.drawer) {
                    this.expand = !this.expand
                } else {
                    this.drawer = !this.drawer
                }
            },
            idle() {},

            createNotification(data) {
                let notificationObject
                if (data.type == "0") {
                    //type 0 = error
                    notificationObject = {
                        type: data.type,
                        message: data.message,
                        icon: "mdi-alert-circle",
                        icon_color: "red",
                        listname: data.listname,
                        date: this.getDateTime(),
                        id: data.id
                    }
                    for (let index = 0; index < this.notifications.length; index++) {
                        if (this.notifications[index].id == data.id) {
                            this.notifications.splice(index, 1)
                            this.messages--
                        }
                    }
                } else if (data.type == "1") {
                    //type 1 = branddashboard is creating
                    notificationObject = {
                        type: data.type,
                        message: data.message,
                        icon: "mdi-clock",
                        icon_color: "orange",
                        progress: 0,
                        listname: data.listname,
                        date: this.getDateTime(),
                        id: data.id
                    }
                } else if (data.type == "2") {
                    //type 1 = branddashboard created
                    // remove id and push new notification
                    for (let index = 0; index < this.notifications.length; index++) {
                        if (this.notifications[index].id == data.id) {
                            this.notifications.splice(index, 1)
                            this.messages--
                        }
                    }
                    notificationObject = {
                        type: data.type,
                        message: data.message,
                        icon: "mdi-comment-check",
                        icon_color: "green",
                        listname: data.listname,
                        date: this.getDateTime(),
                        id: data.id
                    }
                } else if (data.type == "3") {
                    //type 3 = progress update
                    console.log(this.notifications)
                    for (let index = 0; index < this.notifications.length; index++) {
                        if (this.notifications[index].id == data.id) {
                            this.notifications[index].progress = data.progress
                            console.log(this.notifications)
                        }
                    }
                    return null
                }
                return notificationObject
            },
            getDateTime() {
                let dateTime = new Date()
                let month = dateTime.getMonth() + 1
                let day = dateTime.getDate()
                let hours = dateTime.getHours()
                let minutes = dateTime.getMinutes()
                return day + "." + month + " | " + hours + ":" + minutes
            },
            deleteNotification(id) {
                for (let index = 0; index < this.notifications.length; index++) {
                    if (this.notifications[index].id == id) {
                        this.notifications.splice(index, 1)
                        this.messages--
                    }
                }
            },
            resolve_img_url() {
                return "https://eu.ui-avatars.com/api/?name=" + this.decryptedPlan
            }
        },
        watch: {
            // mode(val) {
            //     console.log(val)
            //     setTimeout(() => {
            //         this.user_settings.advancedMode = this.mode
            //     }, 200)
            // },
            drawer(val) {
                if (!val) {
                    let checkbox = (document.getElementById("hamburg").checked = false)
                }
                return
            },
            "user_settings.advancedMode"(val) {
                this.advanced_switch = val
            }
        },
        created() {},
        updated() {
            if (!this.companyGroupJoined && localStorage.company_id) {
                this.$socket.emit("joinCompanyGroup", localStorage.company_id)
                this.companyGroupJoined = true
                // try {
                //     let decrypted_user_id = this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                //     const matomoTracker = this.$matomo.getTracker()
                //     matomoTracker.setUserId(decrypted_user_id)
                // } catch (error) {
                //     console.log(error)
                //     console.log("matomo blocked")
                // }
            }

            //inactive check - log out users of inactive companies
            if (!this.inactiveCheckDone && localStorage.company_id) {
                this.inactiveCheckDone = true
                this.$http.post(process.env.VUE_APP_APIURL + "/user/inactivecheck", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } }).then(function (response) {
                    if (!response.body.active) {
                        delete localStorage.token
                        delete localStorage.user
                        delete localStorage.id
                        delete localStorage.company_id
                        delete localStorage.plan
                        delete localStorage.username
                        this.$router.push("/login")
                    }
                })
            }
        },

        async mounted() {
            this.$router.onReady(() => {
                if (this.$route.query.checkout == "success") {
                    this.showCheckoutSuccess = true
                }
            })

            if (localStorage.user !== undefined) {
                await this.$store.dispatch("FETCH_STARTUP_DATA", this.CryptoJS.AES.decrypt(localStorage.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8))
                if (this.user_settings.dark_mode) {
                    this.$vuetify.theme.light = !this.$vuetify.theme.light
                    this.$vuetify.theme.dark = !this.$vuetify.theme.dark
                }
            }
            this.selectedColor = this.$vuetify.theme.themes.light.primary

            this.$socket.on("showReloadmessage", (data) => {
                console.log("Refreshing frontend initiated...showing message")
                this.showUpdateMessage = true
                setInterval(() => {
                    if (this.timer > 0) {
                        this.timer--
                    }
                }, 1000)
            })
            this.$socket.on("reloadFrontend", (data) => {
                console.log("Refreshing frontend...")
                this.$router.go()
            })

            if (window.HubSpotConversations) {
                console.log("The api is ready already")
            } else {
                window.hsConversationsOnReady = [
                    () => {
                        console.log("Now the api is ready")
                    }
                ]
            }
            // Warten, bis das Pipedrive-Skript geladen ist
            window.addEventListener("load", function () {
                // Zugriff auf das Chat-Button-Element
                setTimeout(() => {
                    var chatButton = document.querySelector("#pipedrive-chat-holder > div > button") // Passen Sie die Selektor-Struktur entsprechend an
                    console.log("load event")
                    console.log(chatButton)
                    // Überprüfen, ob das C   hat-Button-Element gefunden wurde

                    if (chatButton) {
                        // Ändern der Hintergrundfarbe und Textfarbe
                        chatButton.style.backgroundColor = "#FF0000" // Ändern Sie die Hintergrundfarbe nach Ihren Wünschen
                        chatButton.style.color = "#FFFFFF" // Ändern Sie die Textfarbe nach Ihren Wünschen
                        // Weitere Stile können ebenfalls angepasst werden
                    }
                }, 1000)
            })
            window.addEventListener("offline", (event) => {
                console.log("off")
                this.offline = true
                // the user has lost connection here
            })
            window.addEventListener("online", (event) => {
                console.log("on")
                this.offline = false
                // the user has lost connection here
            })
        }
    }
</script>
<style lang="scss">
    @import "./assets/css/global.scss";
    @import "./assets/css/mobile.css";

    .navdivider {
        height: 0.1px;
        width: 72%;
        margin-left: auto;
        margin-right: auto;
        // border-radius: 2px;
        background-color: white;
        border-color: white;
        border: none;
    }

    .v-color-picker__controls {
        display: none !important;
    }
    .v-color-picker__swatch {
        flex-direction: row !important;
        margin-bottom: 0px !important;
        padding-bottom: 0px;
    }

    .usermenuactions {
        align-content: center;
    }
    .usermenulogoutbtn {
        margin-left: auto !important;
    }
    .notification:hover {
        background-color: #e9e9e9;
    }
    .nofitificationtext {
        font-family: Nunito;
    }
    #research_line_chart > #line_chart_card > div.v-card__text {
        height: 100% !important;
    }
    .advanced-mode {
        padding-top: 15px;
        // width: 97%;
        padding-left: 24px;
        padding-right: 24px;
        margin: auto;
        #line_chart_card > div.v-card__text {
            max-height: 350px !important;
            height: 30vh;
            padding-right: 40px !important;
        }

        .init-search-box {
            padding-top: 15px !important;
        }
        #serp_items_table_filter {
            position: absolute;
            bottom: 10px;
            right: 0px;
        }
        #keyword-table table > tbody > tr > td,
        #keyword-table table > thead > tr > th {
            padding: 0px 8px;
        }
        #keyword-table table > tbody > tr > td:nth-child(1),
        #keyword-table table > thead > tr > th:nth-child(1) {
            padding: 0px 16px;
        }
        #trend_table table > tbody > tr > td,
        #trend_table table > thead > tr > th {
            padding: 0px 8px;
        }
        #trend_table table > tbody > tr > td:nth-child(1),
        #trend_table table > thead > tr > th:nth-child(1) {
            padding: 0px 16px;
        }
    }
    .standard-mode {
        padding-top: 30px;
        // max-width: 90%;
        padding-left: 100px;
        padding-right: 100px;
        margin: auto;
    }

    @media only screen and (max-width: 1500px) {
        .standard-mode {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
    @media only screen and (max-width: 800px) {
        .standard-mode {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    // @media screen and (max-height: 1173px) {
    //     #linechart_col {
    //         max-height: 30vh;
    //     }
    // }
    // @media screen and (min-height: 1173px) {
    //     #linechart_col {
    //         height: 36vh;
    //     }
    //     #line_chart_card > div.v-card__text {
    //         flex-grow: 1;
    //         min-height: 0;
    //         // height: 85%;
    //         > div {
    //             position: relative;
    //             height: 95%;
    //         }
    //     }
    // }
    #mobileOverlay {
        // z-index: 9999;
    }
</style>
<style scoped lang="scss">
    .main {
        width: 100%;
        height: 100%;
    }
    .maincard {
        text-align: center;
        margin: 0px;
        padding: 0px;
        height: 100%;
        color: #fff;
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        -webkit-animation: Gradient 15s ease infinite;
        -moz-animation: Gradient 15s ease infinite;
        animation: Gradient 15s ease infinite;
    }
    .vh {
        height: 100%;
        align-items: center;
        display: flex;
    }
    .vh > div {
        width: 100%;
        text-align: center;
        vertical-align: middle;
    }
    img {
        max-width: 100%;
    }
    .wrap {
        margin-top: 50px;
        text-align: center;
    }
    .wrap h1 {
        font-size: 30px;
        font-weight: 700;
        margin: 0 0 90px;
    }
    .wrap h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.6;
        margin: 0 0 80px;
    }
    .msgbox {
        display: flex;
        margin-bottom: 50px;
        .text {
            border: 1px dashed rgb(253, 253, 253);
            border-radius: 5px;
            padding: 2px 7px 2px 7px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    // @-webkit-keyframes Gradient {
    //     0% {
    //         background-position: 0% 50%;
    //     }
    //     50% {
    //         background-position: 100% 50%;
    //     }
    //     100% {
    //         background-position: 0% 50%;
    //     }
    // }
    // @-moz-keyframes Gradient {
    //     0% {
    //         background-position: 0% 50%;
    //     }
    //     50% {
    //         background-position: 100% 50%;
    //     }
    //     100% {
    //         background-position: 0% 50%;
    //     }
    // }
    // @keyframes Gradient {
    //     0% {
    //         background-position: 0% 50%;
    //     }
    //     50% {
    //         background-position: 100% 50%;
    //     }
    //     100% {
    //         background-position: 0% 50%;
    //     }
    // }
    #logo-div {
        width: 130px;
        display: flex;
    }
    #logo {
        margin-top: auto;
        margin-bottom: auto;
    }
    #updatebtn {
        margin-left: 35px;
        // position: absolute;
        // top: 10px;
        // left: 220px;
        background: rgb(28, 237, 179);
        // background: linear-gradient(90deg, rgb(237, 28, 77) 0%, rgba(255, 152, 152, 1) 75%);
        background: linear-gradient(90deg, #009f89 0%, #e68989 75%);
    }
    #notificationline {
        margin-left: 60px !important;
        border-radius: 5px;
        border: 1px solid rgb(228, 228, 228);
    }
    .message {
        font-size: 0.9em;
    }
    .date {
        font-size: 0.8em;
    }
    .listname {
        font-weight: bold;
        font-size: 0.9em;
    }
    .v-badge__badge {
        margin-top: 20px;
    }
    .v-list-group__header {
        height: 100px !important;
    }
    img {
        width: 100%;
        height: auto;
    }
    #logotext {
        max-width: 150px;
        padding-left: 10px;
        margin-top: 1px !important;
    }
    #sidebarLogo {
        margin-left: 5px;
        margin-right: 5px;
        max-width: 50px;
    }
    .sidebarLogo:hover {
        cursor: pointer;
    }
    // background: "rgb(245, 254, 251)",
    //             background_gradient_1:"rgba(245, 254, 251, 1)",
    //             background_gradient_2:"rgba(247, 252, 251, 1)",
    //             background_gradient_3:"rgba(250, 250, 250, 1)",
    //             background_gradient_4:"rgba(249, 245, 247, 1)",
    //             background_gradient_5:"rgba(250, 244, 247, 1)",
    #content {
        background: var(--v-background-base);
        background: linear-gradient(
            90deg,
            var(--v-background_gradient_1-base) 0%,
            var(--v-background_gradient_2-base) 26%,
            var(--v-background_gradient_3-base) 50%,
            var(--v-background_gradient_4-base) 75%,
            var(--v-background_gradient_5-base) 100%
        );
        z-index: 0;
    }
    #contentcontainter {
        margin: auto;
    }
    #subnavIcon {
        padding-left: 39px;
        padding-right: 0px;
        margin-right: 10px;
    }
    #subnavText {
        padding-left: 0px !important;
    }
    .tooltitle:hover {
        cursor: pointer !important;
    }

    .v-application {
        font-family: "Nunito";
    }
    #usersection {
        display: inline-flex !important;
        height: 100%;
        padding-right: 0px;
    }

    .initials {
        color: white;
    }
    .vl {
        padding: 5px;
        padding-right: 7px;
        border-left: 2px solid rgb(209, 209, 209);
        height: 80%;
    }
    .namepart {
        font-size: 0.8em;
        white-space: nowrap;
    }
    .mailpart {
        padding-bottom: 10px;
        font-size: 0.6em;
        white-space: nowrap;
    }
    .idpart {
        padding-top: 3px !important;
        padding-left: 0px;
    }
    .avat {
        background: linear-gradient(56deg, var(--v-left_nav_gradient1-base) 0%, var(--v-left_nav_gradient3-base) 100%);

        // margin-top: 1px;
    }
    .usermenuheader {
        width: 100%;
        height: 50px;
        background: linear-gradient(56deg, var(--v-left_nav_gradient1-base) 0%, var(--v-left_nav_gradient3-base) 100%);
        color: white;
    }
    .avat2 {
        background: white;
        margin-top: 2px;
        color: var(--v-primary-base);
    }
    #userdata_nav {
        padding-top: 2px;
    }
    #nav2 {
        backdrop-filter: blur(8px);
        background: rgba(246, 248, 251, 0.7);
        box-shadow: 0px 8px 21px var(--v-shadow1-base), 8px 0px 21px var(--v-shadow2-base) !important;
        // background-color: var(--v-left_nav_gradient1-base);
    }
    .theme--dark #nav2 {
        background: rgb(73 73 73 / 70%);
    }
    #nav {
        // background: rgb(1, 158, 134);
        z-index: 9;
        background: linear-gradient(180deg, var(--v-left_nav_gradient1-base) 0%, var(--v-left_nav_gradient2-base) 59%, var(--v-left_nav_gradient3-base) 100%);
    }
    // .theme--dark #nav {
    //     background: linear-gradient(180deg, var(--v-left_nav_gradient1-base) 0%, #004339 59%, #001f1b 100%);
    // }

    .subnavtext {
        font-size: 12px;
    }

    // -BUGRER MENU START

    label.hamburg {
        display: block;
        // background: #555;
        width: 22px;
        height: 16px;
        position: relative;
        margin: auto;
        border-radius: 4px;
    }
    label.hamburg {
        cursor: pointer;
    }
    input#hamburg {
        display: none;
    }

    .line {
        position: absolute;
        // left: 10px;
        height: 3px;
        width: 100%;
        background: var(--v-primary-base);
        border-radius: 2px;
        display: block;
        transition: 0.5s;
        transform-origin: center;
    }

    .line:nth-child(1) {
        top: 0px;
    }
    .line:nth-child(2) {
        top: 7px;
    }
    .line:nth-child(3) {
        bottom: -1px;
    }

    #hamburg:checked + .hamburg .line:nth-child(1) {
        transform: translateY(8px) rotate(-45deg);
    }

    #hamburg:checked + .hamburg .line:nth-child(2) {
        opacity: 0;
    }

    #hamburg:checked + .hamburg .line:nth-child(3) {
        transform: translateY(-8px) rotate(45deg);
    }
    .burger-wrapper {
        display: flex;
        width: 55px;
        height: 98%;
        margin-left: -15px;
    }
    .v-toolbar__content {
        padding: 4px 0px !important;
    }

    //BURGER MENU END

    .switch-holder {
        margin-top: auto;
        background-color: #eeeeee;
        display: flex;
        padding: 10px 10px;
    }
    .switch-holder-expand {
        padding: 10px 15px;
    }
    #nav:hover .switch-holder {
        padding: 10px 15px;
    }
    .mode_switch {
        padding-bottom: 1px;
    }
    .switchpadding {
        padding-left: 9px !important;
    }
    .switch-label {
        padding: 0;
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: bolder;
        min-width: 100px !important;
        padding-top: 3px !important;
        display: none;
        margin-top: auto;
        margin-bottom: auto;
    }
    .expand {
        display: flex !important;
    }
    #nav:hover .switch-label {
        display: flex !important;
    }
    .betachip {
        font-weight: bold !important;
    }
    .v-list-item__icon {
        margin-left: -22px !important;
    }
    .v-list-group__header__append-icon {
        margin-left: 0px !important;
    }
    #gradient {
        background: rgb(28, 237, 179);
        background: linear-gradient(90deg, #009f89 0%, #e68989 75%);
    }
</style>
<style lang="scss">
    #cs_nav > div.v-list-group__header.v-list-item.v-list-item--link.theme--dark > div.v-list-item__icon.v-list-group__header__append-icon {
        margin-left: 0px;
        min-width: 24px !important;
    }
    #pipedrive-chat-holder > div > button {
        background: white !important;
    }
</style>
