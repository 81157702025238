export const customThemes = [
    {
        id: null,
        name: "standard",
        light: {
            shadow1: "#dbdbdb",
            shadow2: "#ffffff",
            primary: "#009f89",
            lightgreen: "#1cedb2",
            darkgreen: "#009f89",
            success: "#3cc761",
            error: "#ed2f59",
            errorred: "#ed2f59",
            secondary: "white",
            background: "#ececec",
            keywordcolhover: "#e7e7e7",
            keywordcol: "#f3f3f3", //keyword table first col
            gradient1: "rgba(0, 159, 137, 0.8)", // chart
            gradient2: "rgba(0, 159, 137, 0.3)", // chart
            gradient3: "rgba(0, 159, 137, 0)", // chart
            sparklineGradient1: "rgba(0,159,137,0.40)",
            sparklineGradient2: "rgba(0,194,167,0.25)",
            sparklineGradient3: "rgba(38,222,196,0.1)",
            background: "#f5fefb",
            background_gradient_1: "#f5fefb",
            background_gradient_2: "#f7fcfb",
            background_gradient_3: "#fafafa",
            background_gradient_4: "#f9f5f7",
            background_gradient_5: "#faf4f7",
            topnavbackground: "#f6f8fbB3",
            navcolor: "#B2f6f8fb",
            q_grey: "#ebeaea",
            q_grey_darken_1: "#d4d4d4",
            left_nav_gradient1: "#019e86",
            left_nav_gradient2: "#009e85",
            left_nav_gradient3: "#0a7d6d"
        },
        dark: {
            shadow1: "black",
            shadow2: "black",
            primary: "#009f89",
            secondary: "white",
            lightgreen: "#1cedb2",
            darkgreen: "#009f89",
            background: "#454545",
            success: "#3cc761",
            error: "#ed2f59",
            errorred: "#ed2f59",
            keywordcolhover: "#666666",
            keywordcol: "#000000", //keyword table first col
            gradient1: "rgb(28, 237, 178, 0.8)",
            gradient2: "rgb(28, 237, 178, 0.3)",
            gradient3: "rgb(28, 237, 178, 0)",
            sparklineGradient1: "#65bd9f",
            sparklineGradient2: "#6bffce",
            sparklineGradient3: "rgb(255, 255, 255, 0)",
            topnavbackground: "rgba(50, 50, 50, 0.5)",
            navcolor: "#1e1e1e",
            q_grey: "#5f5f5f",
            q_grey_darken_1: "#d4d4d4",
            left_nav_gradient1: "#019e86",
            left_nav_gradient2: "#004339",
            left_nav_gradient3: "#001f1b"
        }
    },
    {
        id: "quickseo",
        name: "xxxlutz",
        light: {
            shadow1: "#dbdbdb",
            shadow2: "#ffffff",
            primary: "#004279",
            lightgreen: "#1cedb2",
            darkgreen: "#009f89",
            errorred: "#ed2f59",
            secondary: "white",
            background: "#ececec",
            keywordcolhover: "#e7e7e7",
            keywordcol: "#f3f3f3", //keyword table first col
            gradient1: "rgba(0, 66, 121, 0.8)",
            gradient2: "rgba(0, 66, 121, 0.3)",
            gradient3: "rgba(0, 66, 121, 0)",
            sparklineGradient1: "#004279",
            sparklineGradient2: "#0D47A1",
            sparklineGradient3: "#1565C0",
            background: "#f5fefb",
            background_gradient_1: "#f5fefb",
            background_gradient_2: "#f7fcfb",
            background_gradient_3: "#fafafa",
            background_gradient_4: "#f9f5f7",
            background_gradient_5: "#faf4f7",
            topnavbackground: "#f6f8fbB3",
            navcolor: "#B2f6f8fb",
            q_grey: "#ebeaea",
            q_grey_darken_1: "#d4d4d4",
            left_nav_gradient1: "#004279",
            left_nav_gradient2: "#0D47A1",
            left_nav_gradient3: "#1565C0"
        },
        dark: {
            shadow1: "black",
            shadow2: "black",
            primary: "#004279",
            secondary: "white",
            lightgreen: "#1cedb2",
            darkgreen: "#009f89",
            background: "#454545",
            errorred: "#ed2f59",
            keywordcolhover: "#666666",
            keywordcol: "#000000", //keyword table first col
            gradient1: "rgb(28, 237, 178, 0.8)",
            gradient2: "rgb(28, 237, 178, 0.3)",
            gradient3: "rgb(28, 237, 178, 0)",
            sparklineGradient1: "#65bd9f",
            sparklineGradient2: "#6bffce",
            sparklineGradient3: "rgb(255, 255, 255, 0)",
            topnavbackground: "rgba(50, 50, 50, 0.5)",
            navcolor: "#1e1e1e",
            q_grey: "#5f5f5f",
            q_grey_darken_1: "#d4d4d4",
            left_nav_gradient1: "#004279",
            left_nav_gradient2: "#09367c",
            left_nav_gradient3: "#00152d"
        }
    },
    {
        id: "1547",
        name: "obi",
        light: {
            shadow1: "#dbdbdb",
            shadow2: "#ffffff",
            primary: "#f57b42",
            lightgreen: "#f57b42",
            darkgreen: "#f57b42",
            errorred: "#ed2f59",
            secondary: "white",
            background: "#ececec",
            keywordcolhover: "#e7e7e7",
            keywordcol: "#f3f3f3", //keyword table first col
            gradient1: "rgba(255, 140, 0, 0.8)",
            gradient2: "rgba(255, 149, 0, 0.3)",
            gradient3: "rgba(255, 153, 0, 0)",
            sparklineGradient1: "#f57b42",
            sparklineGradient2: "#f57b42",
            sparklineGradient3: "#f57b42",
            background: "#f5fefb",
            background_gradient_1: "#f5fefb",
            background_gradient_2: "#f7fcfb",
            background_gradient_3: "#fafafa",
            background_gradient_4: "#f9f5f7",
            background_gradient_5: "#faf4f7",
            topnavbackground: "#f6f8fbB3",
            navcolor: "#B2f6f8fb",
            q_grey: "#ebeaea",
            q_grey_darken_1: "#d4d4d4",
            left_nav_gradient1: "#f66600",
            left_nav_gradient2: "#f66600",
            left_nav_gradient3: "#b35429"
        },
        dark: {
            shadow1: "black",
            shadow2: "black",
            primary: "#f57b42",
            secondary: "white",
            lightgreen: "#f57b42",
            darkgreen: "#f57b42",
            background: "#454545",
            errorred: "#ed2f59",
            keywordcolhover: "#666666",
            keywordcol: "#000000", //keyword table first col
            gradient1: "rgba(255, 140, 0, 0.8)",
            gradient2: "rgba(255, 149, 0, 0.3)",
            gradient3: "rgba(255, 153, 0, 0)",
            sparklineGradient1: "#f57b42",
            sparklineGradient2: "#f57b42",
            sparklineGradient3: "#f57b42",
            topnavbackground: "rgba(50, 50, 50, 0.5)",
            navcolor: "#1e1e1e",
            q_grey: "#5f5f5f",
            q_grey_darken_1: "#d4d4d4",
            left_nav_gradient1: "#f66600",
            left_nav_gradient2: "#f66600",
            left_nav_gradient3: "#b35429"
        }
    }
]
