import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"

Vue.use(Vuex)

function isActionAllowed(right_id, rights) {
    // console.log(right_id)
    // console.log(rights)

    let found_rights = rights.find((x) => x.rightID === right_id)
    // console.log(found_rights)
    if (found_rights !== undefined) {
        return true
    } else {
        return false
    }
}

export default new Vuex.Store({
    state: {
        query_keywords: [],
        sidebarMenu: false,
        // user_group: undefined,
        user_rights: [],
        user_plan: undefined,
        company_status: undefined,
        company_status_content_suite: undefined,
        company_status_monitoring_suite: undefined,
        monitoring_domain: undefined,
        monitoring_device: undefined,
        admin: false,
        nav_items: [
            // { icon: 'mdi-database', text: 'Collections', redirect: '/' },
            // { icon: 'mdi-lightbulb-group', text: 'Ideas', redirect: '/ideas' },
            // { icon: 'mdi-feature-search', text: 'Suggests', redirect: '/suggests' },
            // { icon: 'mdi-google', text: 'Google Search Volume', redirect: '/searchvolume' },
            // { icon: 'mdi-google-analytics', text: 'Historical Search Volume', redirect: '/searchvolumehistoric' },
            // { icon: 'mdi-view-dashboard', text: 'Brand Dashboards', redirect: '/brands'},
        ],
        show_settings_page: false,
        rate_usd_to_eur: 0,
        save_collection: false,
        open_save_collection_dialog: false,
        set_collection_name: false,
        skip_keep_keywords: false,
        campaign: null,
        actual_date: "",
        tour_running: false,
        header_items: {},
        clipboard_keywords: [],
        clipboard_headers: null,
        send_to_keywords: [],
        active_filter: [],
        user_settings: {},
        fetched_serps: [],
        clipboard_location: {},
        saved_headers: [],
        preview_url: "",
        research_suite: false,
        content_suite: false,
        content_suite_group: {},
        monitoring_suite: false,
        monitoring_suite_group: {},
        selected_location: {}
        // filter: {
        //     active: false,
        //     sv_min: null,
        //     sv_max: null,
        //     cpc_min: null,
        //     cpc_max: null,
        //     included_keywords: null,
        //     excluded_keywords: null,
        //     wordcount_min: null,
        //     wordcount_max: null,
        //     included_serpItems: [],
        //     excluded_serpItems: [],
        //     sea_competition_min: null,
        //     sea_competition_max: null,
        //     kw_difficulty_min: null,
        //     kw_difficulty_max: null
        // }
    },
    mutations: {
        SET_USER_SETTINGS_HEADER(state, value) {
            state.saved_headers = value
        },
        SET_CLIPBOARD_LOCATION(state, value) {
            state.clipboard_location = value
        },
        SET_SEND_TO_KEYWORDS(state, value) {
            state.send_to_keywords = value
        },
        SET_CLIPBOARD_HEADERS(state, value) {
            state.clipboard_headers = JSON.parse(JSON.stringify(value))
        },
        SET_CLIPBOARD_KEYWORDS(state, value) {
            state.clipboard_keywords = value
        },
        SHOW_SIDEBARMENU(state) {
            state.sidebarMenu = true
        },
        SET_TOUR_RUNNING(state, value) {
            state.tour_running = value
            console.log("tour_running: ", state.tour_running)
        },
        SET_SIDEBARMENU(state) {
            state.sidebarMenu = !state.sidebarMenu
        },
        SET_USER_RIGHTS(state, rights) {
            state.user_rights = rights
        },
        SET_COMPANY_STATUS(state, company_status) {
            state.company_status = company_status
        },
        SET_USER_PLAN(state, plan) {
            state.user_plan = plan
        },
        SET_NAV_ITEMS(state, items) {
            state.nav_items = items
        },
        SET_SHOW_SETTINGS_PAGE(state, value) {
            state.show_settings_page = value
        },
        SET_RATE_USD_TO_EUR(state, value) {
            state.rate_usd_to_eur = value
        },
        SET_QUERY_KEYWORDS(state, query_keywords) {
            state.query_keywords = query_keywords
        },
        // TOUR
        SET_SAVE_COLLECTION(state, value) {
            state.save_collection = value
        },
        // TOUR
        SET_OPEN_SAVE_COLLECTION_DIALOG(state, value) {
            state.open_save_collection_dialog = value
        },
        // TOUR
        SET_SET_COLLECTION_NAME(state, value) {
            state.set_collection_name = value
        },
        SET_SKIP_KEEP_KEYWORDS(state, value) {
            state.skip_keep_keywords = value
        },
        SET_CAMPAIGN(state, value) {
            state.campaign = value
        },
        SET_ACTUAL_DATE(state, value) {
            state.actual_date = value
        },
        SET_HEADER_ITEMS(state, items) {
            state.header_items = items
        },
        SET_ACTIVE_FILTER(state, items) {
            state.filter = items
        },
        SET_USER_SETTINGS(state, items) {
            state.user_settings = items
        },
        SET_FETCHED_SERPS(state, items) {
            state.fetched_serps = items
        },
        SET_PREVIEW_URL(state, value) {
            state.preview_url = value
        },
        SET_CONTENT_SUITE(state, value) {
            state.content_suite = value
        },
        SET_RESEARCH_SUITE(state, value) {
            state.research_suite = value
        },
        SET_CONTENT_SUITE_GROUP(state, value) {
            state.content_suite_group = value
        },
        SET_MONITORING_SUITE(state, value) {
            state.monitoring_suite = value
        },
        SET_MONITORING_SUITE_GROUP(state, value) {
            state.monitoring_suite_group = value
        },
        SET_COMPANY_STATUS_CONTENT_SUITE(state, value) {
            state.company_status_content_suite = value
        },
        SET_COMPANY_STATUS_MONITORING_SUITE(state, value) {
            state.company_status_monitoring_suite = value
        },
        SET_ADMIN(state, value) {
            state.admin = value
        },
        SET_SELECTED_LOCATION(state, value) {
            state.selected_location = value
        },
        SET_MONITORING_DOMAIN(state, value) {
            state.monitoring_domain = value
        },
        SET_MONITORING_DEVICE(state, value) {
            state.monitoring_device = value
        }
        // SET_USER_GROUP(state, user_group){
        //   state.user_group = user_group
        // }
    },
    getters: {
        getUserRights(state) {
            return state.user_rights
        },
        getUserPlan(state) {
            return state.user_plan
        },
        getQueryKeywords(state) {
            return state.query_keywords
        },
        getClipboardHeaders(state) {
            return state.clipboard_headers
        },
        getSavedHeaders(state) {
            return state.saved_headers
        },
        getAdmin(state) {
            return state.admin
        }
    },

    actions: {
        SET_MONITORING_DOMAIN(state, value) {
            this.commit("SET_MONITORING_DOMAIN", value)
        },
        SET_MONITORING_DEVICE(state, value) {
            this.commit("SET_MONITORING_DEVICE", value)
        },
        SET_SELECTED_LOCATION(state, value) {
            this.commit("SET_SELECTED_LOCATION", value)
        },
        SET_CLIPBOARD_LOCATION(state, value) {
            this.commit("SET_CLIPBOARD_LOCATION", value)
        },
        SET_ACTUAL_DATE(state, value) {
            this.commit("SET_ACTUAL_DATE", value)
        },
        SET_SEND_TO_KEYWORDS(state, keywords) {
            this.commit("SET_SEND_TO_KEYWORDS", keywords)
        },
        SET_CLIPBOARD_KEYWORDS(state, keywords) {
            this.commit("SET_CLIPBOARD_KEYWORDS", keywords)
        },
        SET_TOUR_RUNNING(state, value) {
            console.log("store")
            this.commit("SET_TOUR_RUNNING", value)
        },
        // TOUR
        SET_SKIP_KEEP_KEYWORDS() {
            this.commit("SET_SKIP_KEEP_KEYWORDS", true)
        },
        // TOUR
        SET_SAVE_COLLECTION() {
            this.commit("SET_SAVE_COLLECTION", true)
        },
        // TOUR
        SET_OPEN_SAVE_COLLECTION_DIALOG() {
            this.commit("SET_OPEN_SAVE_COLLECTION_DIALOG", true)
        },
        // TOUR
        SET_SET_COLLECTION_NAME() {
            this.commit("SET_SET_COLLECTION_NAME", true)
        },
        // TOUR
        UNSET_TOUR_VARIABLES() {
            this.commit("SET_SET_COLLECTION_NAME", false)
            this.commit("SET_OPEN_SAVE_COLLECTION_DIALOG", false)
            this.commit("SET_SAVE_COLLECTION", false)
            this.commit("SET_SKIP_KEEP_KEYWORDS", false)
        },
        SET_CLIPBOARD_HEADERS({ getters }, newHeaders = []) {
            let clipboardHeaders = getters.getClipboardHeaders
            console.log("storeHeaders: ", clipboardHeaders)
            console.log("givenheaders: ", newHeaders)
            if (newHeaders.length == 0) {
                console.log("clear Clipboard headers")
                this.commit("SET_CLIPBOARD_HEADERS", null)
                return
            } else if (!clipboardHeaders) {
                console.log("new Clipboard headers set")
                this.commit("SET_CLIPBOARD_HEADERS", newHeaders)
                return
            }
            // only executes if newHeaders Array exists
            // if (newHeaders !== clipboardHeaders) {
            // only executs if header object is not the same
            newHeaders.modifiedHeaders.map((newHeadersEntry, index) => {
                if (newHeadersEntry.custom) {
                    // only custom headers are considered
                    let indexInClipboardHeaders = clipboardHeaders.modifiedHeaders.findIndex((entry) => entry.value == newHeadersEntry.value)
                    if (indexInClipboardHeaders === -1) {
                        // only executes if headerentry is new and does not exist already in clipborad
                        console.log("new header entry added to clipboardHeaders")
                        clipboardHeaders.modifiedHeaders.splice(index, 0, newHeadersEntry)
                    } else {
                        // header with same value exists in cipboard
                        // add options if differ
                        let existingHeader = clipboardHeaders.modifiedHeaders[indexInClipboardHeaders]
                        if (newHeadersEntry.customOptions.type == "Dropdown" && existingHeader.customOptions.type == "Dropdown") {
                            newHeadersEntry.customOptions.entries.map((newOption) => {
                                let existingOptionIndex = existingHeader.customOptions.entries.findIndex((existingOption) => existingOption.text == newOption.text)
                                console.log(newOption, existingOptionIndex)
                                if (existingOptionIndex === -1) {
                                    // making sure to not have double ids
                                    let lastID = existingHeader.customOptions.entries[existingHeader.customOptions.entries.length - 1].id
                                    newOption.id = lastID + 1
                                    existingHeader.customOptions.entries.push(newOption)
                                    console.log("new Option added to existing custom header")
                                }
                            })
                        }
                    }
                }
            })
            newHeaders.standardHeaders.map((newHeadersEntry, index) => {
                if (newHeadersEntry.custom) {
                    let indexInClipboardHeaders = clipboardHeaders.standardHeaders.findIndex((entry) => entry.value == newHeadersEntry.value)
                    if (indexInClipboardHeaders === -1) {
                        // only executes if headerentry is new and does not exist already in clipborad
                        clipboardHeaders.standardHeaders.splice(index, 0, newHeadersEntry)
                    } else {
                        // header with same value exists in cipboard
                        // add options if differ
                        let existingHeader = clipboardHeaders.standardHeaders[indexInClipboardHeaders]
                        if (newHeadersEntry.customOptions.type == "Dropdown" && existingHeader.customOptions.type == "Dropdown") {
                            newHeadersEntry.customOptions.entries.map((newOption) => {
                                let existingOptionIndex = existingHeader.customOptions.entries.findIndex((existingOption) => existingOption.text == newOption.text)
                                if (existingOptionIndex === -1) {
                                    // making sure to not have double ids
                                    let lastID = existingHeader.customOptions.entries[existingHeader.customOptions.entries.length - 1].id
                                    newOption.id = lastID + 1
                                    existingHeader.customOptions.entries.push(newOption)
                                }
                            })
                        }
                    }
                }
            })

            //find new sv headers and palce them into clipboard headers
            let clipboardStandardSvHeadersIndex = clipboardHeaders.standardHeaders.findIndex((p) => p.name === "sv_headers")
            let newStandardSvHeadersIndex = newHeaders.standardHeaders.findIndex((p) => p.name === "sv_headers")
            let clipboardModifiedSvHeadersIndex = clipboardHeaders.modifiedHeaders.findIndex((p) => p.name === "sv_headers")

            // new sv header to push in standard and modified headers
            let newSvHeaders = []
            newHeaders.standardHeaders[newStandardSvHeadersIndex].columns.map((x) => {
                let indexInClipboardHeaders = clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns.findIndex((entry) => entry.value == x.value)
                if (indexInClipboardHeaders === -1) {
                    // only executes if headerentry is new and does not exist already in clipborad
                    newSvHeaders.push(x)
                }
            })
            console.log(newSvHeaders)

            // push new sv headers in standard headers
            for (let newSvHeaderIndex = 0; newSvHeaderIndex < newSvHeaders.length; newSvHeaderIndex++) {
                //check if header bigger than newest date
                console.log(clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns[0].value)
                if (
                    newSvHeaders[newSvHeaderIndex].value >
                    clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns[clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns.length - 1].value
                ) {
                    clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns.push(newSvHeaders[newSvHeaderIndex])
                } else {
                    for (let columnIndex = 0; columnIndex < clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns.length; columnIndex++) {
                        if (newSvHeaders[newSvHeaderIndex].value < clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns[columnIndex].value) {
                            clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns.splice(columnIndex, 0, newSvHeaders[newSvHeaderIndex])
                            columnIndex = clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns.length
                        }
                    }
                }
            }
            console.log(clipboardHeaders.standardHeaders[clipboardStandardSvHeadersIndex].columns)

            // push new sv headers in modified headers
            for (let newSvHeaderIndex = 0; newSvHeaderIndex < newSvHeaders.length; newSvHeaderIndex++) {
                //check if header bigger than newest date
                console.log(clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns[0].value)
                if (
                    newSvHeaders[newSvHeaderIndex].value >
                    clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns[clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns.length - 1].value
                ) {
                    clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns.push(newSvHeaders[newSvHeaderIndex])
                } else {
                    for (let columnIndex = 0; columnIndex < clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns.length; columnIndex++) {
                        if (newSvHeaders[newSvHeaderIndex].value < clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns[columnIndex].value) {
                            clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns.splice(columnIndex, 0, newSvHeaders[newSvHeaderIndex])
                            columnIndex = clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns.length
                        }
                    }
                }
            }
            console.log(clipboardHeaders.modifiedHeaders[clipboardModifiedSvHeadersIndex].columns)
            console.log("new Clipboard headers added")
            this.commit("SET_CLIPBOARD_HEADERS", clipboardHeaders)
            console.log("storeHeaders: ", clipboardHeaders)
            // } else {
            //     console.log("same headers already in clipboard")
            // }
        },
        CHANGE_NAV_ITEMS({ getters }) {
            let items = [
                { icon: "mdi-database", text: "Collections", redirect: "/collections" },
                { icon: "mdi-lightbulb-group", text: "Ideas", redirect: "/ideas" },
                { icon: "mdi-feature-search", text: "Suggests", redirect: "/suggests" },
                {
                    icon: "mdi-google",
                    text: "Google Search Volume",
                    redirect: "/searchvolume"
                },
                // {
                //     icon: "mdi-message-question",
                //     text: "Questions",
                //     redirect: "/questions"
                // },
                // {
                //     icon: "mdi-progress-star",
                //     text: "Advanced Search",
                //     redirect: "/advanced"
                // },
                {
                    icon: "mdi-view-dashboard",
                    text: "Brand Dashboards",
                    redirect: "/brands"
                }
            ]
            let user_rights = getters.getUserRights
            let user_plan = getters.getUserPlan
            let admin = getters.getAdmin

            // 2: No Brand Dashboards
            if ((!isActionAllowed(12, user_rights) && !admin) || user_plan == 2 || user_plan == 1 || user_plan == 3) {
                // 1,2 = No Brand Dashboards
                for (let itemCounter = 0; itemCounter < items.length; itemCounter++) {
                    if (items[itemCounter].text == "Brand Dashboards") {
                        items.splice(itemCounter, 1)
                    }
                }
            }
            if ((!isActionAllowed(11, user_rights) && !admin) || user_plan == 2) {
                // 2 = No Historical SV
                for (let itemCounter = 0; itemCounter < items.length; itemCounter++) {
                    if (items[itemCounter].text == "Historical Search Volume") {
                        items.splice(itemCounter, 1)
                    }
                }
            }
            if (!admin) {
                if (!isActionAllowed(10, user_rights)) {
                    for (let itemCounter = 0; itemCounter < items.length; itemCounter++) {
                        if (items[itemCounter].text == "Google Search Volume") {
                            items.splice(itemCounter, 1)
                        }
                    }
                }
                if (!isActionAllowed(9, user_rights)) {
                    for (let itemCounter = 0; itemCounter < items.length; itemCounter++) {
                        if (items[itemCounter].text == "Suggests") {
                            items.splice(itemCounter, 1)
                        }
                    }
                }
                if (!isActionAllowed(8, user_rights)) {
                    for (let itemCounter = 0; itemCounter < items.length; itemCounter++) {
                        if (items[itemCounter].text == "Ideas") {
                            items.splice(itemCounter, 1)
                        }
                    }
                }
                if (!isActionAllowed(1, user_rights)) {
                    for (let itemCounter = 0; itemCounter < items.length; itemCounter++) {
                        if (items[itemCounter].text == "Collections") {
                            items.splice(itemCounter, 1)
                        }
                    }
                }
            }
            if (user_plan !== 3 || admin) {
                this.commit("SET_SHOW_SETTINGS_PAGE", true)
            } else {
                this.commit("SET_SHOW_SETTINGS_PAGE", false)
            }

            this.commit("SET_NAV_ITEMS", items)
        },
        SHOW_SIDEBARMENU() {
            this.commit("SHOW_SIDEBARMENU")
        },
        CHANGE_SIDEBARMENU() {
            this.commit("SET_SIDEBARMENU")
        },
        CHANGE_HEADER_ITEMS(state, { items }) {
            this.commit("SET_HEADER_ITEMS", items)
        },
        CHANGE_ACTIVE_FILTER(state, items) {
            this.commit("SET_ACTIVE_FILTER", items)
        },
        DELETE_USER_SETTINGS_HEADER(state, items) {
            this.commit("SET_USER_SETTINGS_HEADER", items)
            this.dispatch("SAVE_USER_SETTINGS_HEADER", items)
        },
        CHANGE_USER_SETTINGS_HEADER({ getters }, items) {
            let saved_headers = getters.getSavedHeaders
            saved_headers.unshift(items)
            // if (saved_headers.length > 10) saved_headers.length = 10
            this.commit("SET_USER_SETTINGS_HEADER", saved_headers)
            this.dispatch("SAVE_USER_SETTINGS_HEADER", saved_headers)
        },
        SAVE_USER_SETTINGS_HEADER(state, items) {
            axios({
                method: "post",
                url: process.env.VUE_APP_APIURL + "/service/settings/header/update",
                data: {
                    user_id: localStorage.id,
                    company_id: localStorage.company_id,
                    saved_headers: items
                },
                headers: {
                    Authorization: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
                }
            })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
                .then(function () {
                    // always executed
                })
        },
        CHANGE_USER_SETTINGS(state, items) {
            this.commit("SET_USER_SETTINGS", items)
            axios({
                method: "post",
                url: process.env.VUE_APP_APIURL + "/service/settings/update",
                data: {
                    user_id: localStorage.id,
                    company_id: localStorage.company_id,
                    settings: items
                },
                headers: {
                    Authorization: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
                }
            })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
                .then(function () {
                    // always executed
                })
        },
        CHANGE_FETCHED_SERPS(state, items) {
            this.commit("SET_FETCHED_SERPS", items)
        },
        CHANGE_PREVIEW_URL(state, value) {
            this.commit("SET_PREVIEW_URL", value)
        },
        FETCH_STARTUP_DATA(state, user) {
            return new Promise(async (resolve, reject) => {
                Vue.prototype.$setTheme()
                axios({
                    method: "post",
                    url: process.env.VUE_APP_APIURL + "/service/startup",
                    data: {
                        user: user.toLowerCase(),
                        user_id: localStorage.id,
                        company_id: localStorage.company_id
                    },
                    headers: {
                        Authorization: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
                    }
                })
                    .then((response) => {
                        console.log(response)
                        // this.commit('SET_USER_GROUP', response.data.group_id)
                        this.commit("SET_USER_RIGHTS", response.data.userRights)
                        this.commit("SET_RATE_USD_TO_EUR", response.data.rate_usd_to_eur)
                        this.commit("SET_USER_PLAN", response.data.plan.plan)
                        this.commit("SET_ADMIN", response.data.admin)
                        this.commit("SET_COMPANY_STATUS", response.data.company_status)
                        this.commit("SET_CAMPAIGN", response.data.campaign)
                        this.commit("SET_ACTUAL_DATE", response.data.actual_date)
                        this.commit("SET_USER_SETTINGS", response.data.userSettings)
                        this.commit("SET_USER_SETTINGS_HEADER", response.data.saved_headers)
                        this.commit("SET_RESEARCH_SUITE", response.data.research_suite)
                        this.commit("SET_CONTENT_SUITE", response.data.content_suite)
                        this.commit("SET_CONTENT_SUITE_GROUP", response.data.content_suite_group)
                        this.commit("SET_COMPANY_STATUS_CONTENT_SUITE", response.data.company_status_content_suite)
                        this.commit("SET_MONITORING_SUITE", response.data.monitoring_suite)
                        this.commit("SET_MONITORING_SUITE_GROUP", response.data.monitoring_suite_group)
                        this.commit("SET_COMPANY_STATUS_MONITORING_SUITE", response.data.company_status_monitoring_suite)
                        this.commit("SET_MONITORING_DOMAIN", response.data.monitoring_domain)
                        this.commit("SET_MONITORING_DEVICE", response.data.monitoring_device)

                        this.dispatch("CHANGE_NAV_ITEMS")
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    .then(function () {
                        // always executed
                    })
            })
        }
    },
    modules: {}
})
